<template>
  <b-sidebar
    v-if="$can('update', 'clients')"
    id="add-new-client-sites-sidebar"
    :visible="isAddNewClientSitesSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-client-sites-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Client Site
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="observer"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(validateForm)"
          @reset.prevent="resetForm"
        >

          <!-- sitename -->
          <b-form-group
            label="Site Name"
            label-for="sitename"
          >
            <validation-provider
              #default="{ errors }"
              name="sitename"
              rules="required"
            >
              <b-form-input
                id="sitename"
                v-model="clientSitesData.sitename"
                name="sitename"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- clientname -->
          <b-form-group
            label="Client Name"
            label-for="clientname"
          >
            <validation-provider
              #default="{ errors }"
              name="clientname"
              rules="required"
            >
              <b-form-input
                id="clientname"
                v-model="clientSitesData.clientname"
                :readonly="true"
                name="clientname"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- address -->
          <b-form-group
            label="Address"
            label-for="address"
          >
            <validation-provider
              #default="{ errors }"
              name="address"
            >
              <b-form-input
                id="address"
                v-model="clientSitesData.address"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- address unit -->
          <b-form-group
            label="Unit"
            label-for="addressUnit"
          >
            <validation-provider
              #default="{ errors }"
              name="addressUnit"
            >
              <b-form-input
                id="addressUnit"
                v-model="clientSitesData.addressUnit"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- City -->
          <b-form-group
            label="City"
            label-for="city"
          >
            <validation-provider
              #default="{ errors }"
              name="city"
            >
              <b-form-input
                id="city"
                v-model="clientSitesData.city"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- country -->
          <b-form-group
            label="Country"
            label-for="country"
          >
            <validation-provider
              #default="{ errors }"
              name="country"
            >
              <v-select
                v-model="clientSitesData.country"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="countryOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="is_active"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- province -->
          <b-form-group
            label="State / Province"
            label-for="province"
          >
            <validation-provider
              #default="{ errors }"
              name="province"
            >
              <v-select
                v-model="clientSitesData.province"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="provinceOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="is_active"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- postal -->
          <b-form-group
            label="Postal"
            label-for="postal"
          >
            <validation-provider
              #default="{ errors }"
              name="postal"
            >
              <b-form-input
                id="postal"
                v-model="clientSitesData.postal"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- phone -->
          <b-form-group
            label="Phone"
            label-for="phone"
          >
            <validation-provider
              #default="{ errors }"
              name="phone"
            >
              <b-input-group>
                <cleave
                  id="phone"
                  v-model="clientSitesData.phone"
                  class="form-control"
                  :raw="false"
                  :options="options.phone"
                  placeholder="123 123 1234"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- Field: Services -->
          <b-form-group
            label="Services"
            label-for="services"
          >
            <validation-provider
              #default="{ errors }"
              name="services"
            >
              <b-form-checkbox
                v-model="clientSitesData.services.guard"
                class="mb-1"
                name="check-button"
                switch
                inline
              >
                <h5>
                  Guard Services
                </h5>
              </b-form-checkbox>
              <br>
              <b-form-checkbox
                v-model="clientSitesData.services.videomonitoring"
                class="mb-1"
                name="check-button"
                switch
                inline
              >
                <h5>
                  Video Monitoring Services
                </h5>
              </b-form-checkbox>
              <br>
              <b-form-checkbox
                v-model="clientSitesData.services.videoretention"
                class="mb-1"
                name="check-button"
                switch
                inline
              >
                <h5>
                  Video Retention Services
                </h5>
              </b-form-checkbox>
              <br>
              <b-form-checkbox
                v-model="clientSitesData.services.guardtour"
                class="mb-1"
                name="check-button"
                switch
                inline
              >
                <h5>
                  Guard Tour Services
                </h5>
              </b-form-checkbox>
              <br>
              <b-form-checkbox
                v-model="clientSitesData.services.tenant"
                class="mb-1"
                name="check-button"
                switch
                inline
              >
                <h5>
                  Tenant Services
                </h5>
              </b-form-checkbox>
              <br>
              <b-form-checkbox
                v-model="clientSitesData.services.privateinvestigation"
                class="mb-1"
                name="check-button"
                switch
                inline
              >
                <h5>
                  Private Investigation Services
                </h5>
              </b-form-checkbox>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- Field: Status -->
          <b-form-group
            label="Status"
            label-for="is_active"
          >
            <validation-provider
              #default="{ errors }"
              name="is_active"
            >
              <v-select
                v-model="clientSitesData.is_active"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="is_active"
                name="is_active"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-if="$can('create', 'clients')"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton, BFormCheckbox, BInputGroup,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import { ref } from '@vue/composition-api'
import { required, email } from '@validations'
// import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
// Phone number imports
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

// Notification
// import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ability from '../../../../libs/acl/ability'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BButton,
    BInputGroup,
    vSelect,
    Cleave,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewClientSitesSidebarActive',
    event: 'update:is-add-new-client-sites-sidebar-active',
  },
  props: {
    isAddNewClientSitesSidebarActive: {
      type: Boolean,
      required: true,
    },
    clientNameProp: {
      type: String,
      required: false,
      default: '',
    },
    clientDisplayNameProp: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      required,
      email,
      countries,
      blankClientSitesData: {
        sitename: '',
        clientname: '',
        address: '',
        addressUnit: '',
        city: '',
        country: '',
        province: '',
        postal: '',
        phone: '',
        tenant_password: '',
        max_permits_per_week: '5',
        license_plate_restrictions: '',
        console_orderby: 'LocationName',
        emails: [],
        is_active: true,
      },
      clientSitesData: {
        is_active: true,
        services: { guard: false },
      },
      // Phone number imports
      options: {
        phone: {
          phone: true,
          phoneRegionCode: 'US',
        },
      },
    }
  },
  setup() {
    const statusOptions = store.state.appSettings.is_active
    const countryOptions = store.state.appSettings.countries
    const provinceOptions = store.state.appSettings.provinces

    return {
      statusOptions,
      countryOptions,
      provinceOptions,
    }
  },
  created() {
    if (this.clientNameProp !== '') {
      this.clientSitesData.clientname = this.clientNameProp
    }
  },
  methods: {
    validateForm() {
      console.log('Form Validated - Submitting:')
      console.log(this.clientSitesData)

      if (ability.can('update', 'clients')) {
        // Determine the Client Display Name for the New User
        this.clientSitesData.client_display_name = this.clientDisplayNameProp
        // fill in rest of fields
        if (typeof this.clientSitesData.address === 'undefined' || this.clientSitesData.address === null) {
          this.clientSitesData.address = ''
        }
        if (typeof this.clientSitesData.addressUnit === 'undefined' || this.clientSitesData.addressUnit === null) {
          this.clientSitesData.addressUnit = ''
        }
        if (typeof this.clientSitesData.city === 'undefined' || this.clientSitesData.city === null) {
          this.clientSitesData.postal = ''
        }
        if (typeof this.clientSitesData.country === 'undefined' || this.clientSitesData.country === null) {
          this.clientSitesData.country = ''
        }
        if (typeof this.clientSitesData.province === 'undefined' || this.clientSitesData.province === null) {
          this.clientSitesData.province = ''
        }
        if (typeof this.clientSitesData.phone === 'undefined' || this.clientSitesData.phone === null) {
          this.clientSitesData.phone = ''
        }
        if (typeof this.clientSitesData.client_type === 'undefined') {
          this.clientSitesData.client_type = ''
        }
        if (typeof this.clientSitesData.bill_rate === 'undefined') {
          this.clientSitesData.bill_rate = ''
        }
        if (typeof this.clientSitesData.region_loc === 'undefined') {
          this.clientSitesData.region_loc = ''
        }
        if (typeof this.clientSitesData.notes_admin === 'undefined') {
          this.clientSitesData.notes_admin = ''
        }
        if (typeof this.clientSitesData.notes_client === 'undefined') {
          this.clientSitesData.notes_client = ''
        }
        if (typeof this.clientSitesData.notes_public === 'undefined') {
          this.clientSitesData.notes_public = ''
        }
        if (typeof this.clientSitesData.tenant === 'undefined') {
          this.clientSitesData.tenant = { url: '', password: '', max_permits: '' }
        }
        if (typeof this.clientSitesData.geofence === 'undefined') {
          this.clientSitesData.geofence = { lat: '', lon: '', distance: '' }
        }
        if (typeof this.clientSitesData.contacts === 'undefined') {
          this.clientSitesData.contacts = []
        }
        this.$refs.observer.validate().then(success => {
          if (success) {
            store.dispatch('app-client/addClientSite', this.clientSitesData)
              .then(() => {
                this.$emit('update:is-add-new-client-sites-sidebar-active', false)
                this.$emit('newSiteAdded', this.clientSitesData.sitename)
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'New client site created',
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
              })
              .catch(error => {
                console.log(error)
                let theErrorStr = 'The following error(s) where received:\n'
                if (typeof error.response.data.errors !== 'undefined') {
                  error.response.data.errors.forEach(item => {
                    theErrorStr += `${item.message}\n`
                  })
                }
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Error adding client site information.',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: theErrorStr,
                  },
                })
              })
          }
        })
      }
    },
    resetForm() {
      const { clientname } = this.clientSitesData
      this.clientSitesData = JSON.parse(JSON.stringify(this.blankClientSitesData))
      this.clientSitesData.clientname = clientname
      console.log(this.blankClientSitesData)
      console.log(this.clientSitesData)
      this.$refs.observer.reset()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-client-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
